
import { loadingText } from '@/utils/defaultGoalConfig'
import { Component, Vue } from 'vue-property-decorator'
import { PaginationParam } from '@/views/type'
import Pagination from '@/components/Pagination/index.vue'
import numsTips from '@/components/numsTips/index.vue'
import { getProvinceList, getCityList, getCountyList, getTownshipList } from '@/api/peasantInfoManagement'
import { creditRatingDrop, getAssessmentHistoryList, switchPriority, manualSave, creditTaskAdd } from '@/api/creditRatingManagement'
@Component({
  components: {
    numsTips,
    Pagination
  }
})
export default class FarmerCreditJudge extends Vue {
  private loadingTxt: string = loadingText
  private isCreditOptions: any = [
    { label: '是', value: '1' },
    { label: '否', value: '0' }
  ]
  private priorityOptions: any = [
    { label: '系统等级优先', value: 1 },
    { label: '管理员等级优先', value: 2 }
  ]
  private provinceOptions: any = []
  private cityOptions: any = []
  private countyOptions: any = []
  private townshipOptions: any = []
  private creditRatingOptions: any = []
  private isAssessOptions: any = []
  private formInfo: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    name: '',
    systemCreditRatingId: '',
    creditRatingId: '',
    isAssess: '',
    priority: '',
    isCredit: ''
  }
  private paginationParam: PaginationParam = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private tableData: any = []
  private loading = false
  private requestEnd: boolean = true
  // 筛选条件
  private creditInfo: any = {}
  // 选择的数据
  private changeData: any = []
  // 手动评定弹窗
  private show: boolean = false
  private manualInfo: string = ''
  // 搜索状态
  private infoStatus: boolean = false

  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'FarmerCreditJudgeDetails' && pageParams) {
        pageParams = JSON.parse(pageParams)
        Object.keys(pageParams).forEach((key) => {
          vim[key] = pageParams[key]
        })
      }
      if (!vim.provinceOptions.length) {
        getProvinceList().then((res) => {
          if (res.data.data) {
            vim.provinceOptions = res.data.data
          }
        })
      }
      if (!vim.creditRatingOptions.length || !vim.isAssessOptions.length) {
        creditRatingDrop({}).then((res) => {
          if (res.data.data) {
            let ratings = res.data.data.ratings,
              table = res.data.data.table
            ratings && (vim.creditRatingOptions = ratings)
            table && table.credit_assessor_rel && table.credit_assessor_rel.is_assess && (vim.isAssessOptions = table.credit_assessor_rel.is_assess)
          }
        })
      }
      vim.getList()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'FarmerCreditJudgeDetails') {
      let pageParams: any = {
        formInfo: this.formInfo,
        paginationParam: this.paginationParam,
        provinceOptions: this.provinceOptions,
        cityOptions: this.cityOptions,
        countyOptions: this.countyOptions,
        townshipOptions: this.townshipOptions,
        creditRatingOptions: this.creditRatingOptions,
        isAssessOptions: this.isAssessOptions
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.formInfo).forEach((key) => {
      this.formInfo[key] = ''
    })
    this.cityOptions = []
    this.countyOptions = []
    this.townshipOptions = []
  }
  private getList() {
    this.loading = true
    const params = {
      ...this.formInfo,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize
    }
    Object.keys(params).forEach((key) => {
      params[key] && (key == 'province' || key == 'city' || key == 'county' || key == 'township') && (params[key] = params[key].split(',')[1])
      !params[key] && delete params[key]
    })
    this.creditInfo = params
    getAssessmentHistoryList(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
          this.infoStatus = true
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
  private getApprovalStatus(status: any) {
    let returnVal: any = status
    if (this.isAssessOptions && this.isAssessOptions.length) {
      for (let i = 0; i < this.isAssessOptions.length; i++) {
        if (status == this.isAssessOptions[i].value) {
          returnVal = this.isAssessOptions[i].name
          break
        }
      }
    }
    return returnVal
  }
  private selecttedProvince(value: any) {
    this.infoStatus = false
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCityList({ provinceCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCity(value: any) {
    this.infoStatus = false
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCountyList({ cityCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  private selecttedCounty(value: any) {
    this.infoStatus = false
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getTownshipList({ areaCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 改变，更新搜索结果状态
  private changeInfoStatus() {
    this.infoStatus = false
  }
  // 搜索农户信用评定列表
  private handleSearch() {
    this.getList()
  }
  // 查看农户信用评定详情
  private handleDetails(row: any) {
    this.$router.push({
      name: 'FarmerCreditJudgeDetails',
      params: {
        id: row.id,
        farmerId: row.farmerId
      }
    })
  }
  // 勾选表格数据
  private handleChange(val: any) {
    this.changeData = val
  }
  // 自动评定
  private automaticJudge() {
    if (!this.infoStatus) {
      return this.$message.error('请先进行搜索，再操作')
    }
    let isAssess = false
    let changeData = this.changeData.map((item: any) => {
      if (item.isAssess == 0) {
        isAssess = true
      }
      return item.id
    })
    if (isAssess) {
      this.$confirm('选择数据中存在评定中数据，请重新选择！', '自动评定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      return
    }
    this.$confirm('是否确认开始自动评定？', '自动评定', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let params: any = {}
      if (changeData.length < 1) {
        let info = this.creditInfo
        delete info.pageNumber
        delete info.pageSize
        params = { ...info }
      } else {
        params.beAssessors = changeData
      }
      this.loading = true
      creditTaskAdd(params)
        .then((res: any) => {
          this.$message.success('自动评定成功')
          this.getList()
        })
        .finally(() => {
          this.loading = false
        })
    })
  }
  // 评审小组评定
  private manualJudge() {
    if (!this.infoStatus) {
      return this.$message.error('请先进行搜索，再操作')
    }
    let matic = false
    let assess = false
    let idList = this.changeData.map((item: any) => {
      if (!item.systemCreditRating) {
        matic = true
      }
      if (item.isAssess == 0) {
        assess = true
      }
      return item.id
    })
    if (idList.length < 1 && !this.formInfo.township) {
      return this.$message.error('对搜索结果进行操作请先选择筛选条件，至少到街道/乡镇！')
    }
    if (matic) {
      return this.$message.error('进行评审小组评定操作，请先自动评定！')
    }
    if (assess) {
      // this.$confirm('选择数据中存在评定中数据，请重新选择！', '评审小组评定', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // })
      return this.$message.error('只允许评定已评定数据！')
    }
    if (!this.show) {
      this.show = true
      return
    }
    if (!this.manualInfo) {
      return this.$message.error('请先选择评定等级')
    }
    let id = this.manualInfo
    let name = ''
    this.creditRatingOptions.map((item: any) => {
      if (item.id == id) {
        name = item.name
      }
    })

    let params: any = {
      type: 1,
      id,
      name
    }
    if (idList.length < 1) {
      let info = this.creditInfo
      delete info.pageNumber
      delete info.pageSize
      params.type = 2
      params.creditAssessorRel = info
    } else {
      params.idList = idList
    }
    this.loading = true
    manualSave(params)
      .then((res: any) => {
        this.$message.success('评审小组评定成功')
        this.getList()
      })
      .finally(() => {
        this.manualInfo = ''
        this.show = false
        this.loading = false
      })
  }
  // 切换优先级
  private changePriority() {
    if (!this.infoStatus) {
      return this.$message.error('请先进行搜索，再操作')
    }
    let info = this.creditInfo
    delete info.pageNumber
    delete info.pageSize
    let changeData = this.changeData.map((item: any) => {
      return {
        id: item.id,
        priority: item.priority,
        creditRating: item.creditRating
      }
    })
    if (changeData.length < 1 && !this.formInfo.township) {
      return this.$message.error('对搜索结果进行操作请先选择筛选条件，至少到街道/乡镇！')
    }
    let params = {
      creditAssessorRelRequest: info,
      creditEFStatusRequests: changeData
    }
    this.loading = true
    switchPriority(params)
      .then((res: any) => {
        this.$message.success('切换展示优先级成功')
        this.getList()
      })
      .finally(() => {
        this.loading = false
      })
  }
}
